<!-- SAAS云服务菜单页面组件 -->
<template>
  <div class="mobile-container mobile-cloud-platform-main" v-title="'SAAS云服务'">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="body-banner">
        <img src="@/assets/image/mobile/photo-53.jpg">
        <div class="banner-content">
          <span class="banner-content-title">SAAS云服务</span>
          <span>全维度应用覆盖的物流云平台</span>
        </div>
      </div>
      <mobile-cloud-platform></mobile-cloud-platform>
    </div>
    <!-- <div class="footer">
      <mobile-footer></mobile-footer>
    </div> -->
    <!-- 返回主页 -->
    <div class="mobile-home" @click="back">
      <img src="@/assets/image/mobile/icon-32.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  // 内容
  import MobileCloudPlatform from '@/components/mobile/mobile-cloud-platform/mobile-cloud-platform'
  // 底部
  // import MobileFooter from '@/components/mobile/mobile-footer/mobile-footer'

  export default {
    name: 'MobileCloudPlatformMain',
    components: {
      MobileHeader,
      MobileCloudPlatform
      // MobileFooter
    },
    methods: {
      back () {
        this.$router.push({
          path: '/mobileHome'
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-cloud-platform-main {
    .body {
      .mobile-cloud-platform {
        height: calc(100vh - 0.88rem);
      }
    }
  }
</style>
